import Link from 'next/link';
import React from 'react';
import dynamic from 'next/dynamic';
import { Col, Row } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';

import Head from '../components/head';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import OpenGraphDescription from '../components/OpenGraphDescription';
import OpenGraphTitle from '../components/OpenGraphTitle';
import Section from '../components/Section';
import { FranchiseeProvider } from '../context/Franchisee';
import '../ui/src/scripts/routes/common';
import { HomeDifferenceSection } from '../components/Sections/HomeDifferenceSection';
import { RotatingTextSection } from '../components/Sections/RotatingTextSection';

const ReviewsCarousel = dynamic(() => import('../components/ReviewsCarousel'), {
  ssr: false,
});

const FeaturedInSection = dynamic(
  () => import('../components/FeaturedInSection'),
  { ssr: false }
);

const IndexPage: React.FC = () => {
  return (
    <FranchiseeProvider>
      <Layout>
        <Head
          slug='home'
          title='House Cleaners, Maid Service Near Me - Two Maids'
          description='Two Maids is a team of trained house cleaners, providing professional services to homeowners nationwide. Let us do all of the dirty work for you!'
        />
        <OpenGraphTitle title='House Cleaners, Maid Service Near Me - Two Maids' />
        <OpenGraphDescription>
          Two Maids is a team of trained house cleaners, providing professional
          services to homeowners nationwide. Let us do all of the dirty work for
          you!
        </OpenGraphDescription>

        <Hero />

        <RotatingTextSection />

        <Section className='house-cleaning-experts-section'>
          <Col>
            <h2>Your Resident Home Cleaning Expert</h2>
            <p>
              At Two Maids, our purpose is to make a difference in people's
              daily lives. With our maid service, we help busy families maintain
              beautiful, clean, and healthy homes by taking care of the chores
              that no one has the time or energy to do. We proudly serve our
              customers, putting their needs ahead of our own so they can focus
              on what matters most to them. As the best house cleaning service
              on the market, we pride ourselves on care, quality, and a
              sparkling home cleaning. Everything we do makes lives a little
              easier and homes a little brighter.
            </p>
          </Col>
          <Row className='justify-content-between'>
            <Col
              xs={12}
              md={4}
              className='home-services__service px-4 mb-5 mb-md-0'
            >
              <img
                src='https://res.cloudinary.com/twomaidsengi/image/upload/c_scale,f_auto,q_auto,w_300/v1670257150/hubsite-reskin/clipboard.png'
                width='100%'
                height='100%'
                alt='A Clipboard'
                loading='lazy'
              />
              <h3>WE SUPPLY EVERYTHING FOR YOUR CLEANING NEEDS</h3>
              <p>
                There's not a standard cleaning product we don't supply. Two
                Maids provides all the cleaning supplies and equipment needed to
                clean your home. Be it a one-time deep clean, or a recurring
                cleaning, we bring the cleaning supplies so that you can have
                one less thing to worry about.
              </p>
            </Col>
            <Col
              xs={12}
              md={4}
              className='home-services__service px-4 mb-5 mb-md-0'
            >
              <img
                src='https://res.cloudinary.com/twomaidsengi/image/upload/c_scale,f_auto,q_auto,w_300/v1670257150/hubsite-reskin/okay-hand.webp'
                width='100%'
                height='100%'
                alt='A-okay Hand'
                loading='lazy'
              />
              <h3>EXPERIENCED HOUSE CLEANERS</h3>
              <p>
                Our team of experienced house cleaners undergoes thorough
                training, background checks, and is fully bonded and insured.
                They bring professionalism, efficiency, and a friendly demeanor
                to every cleaning job, ensuring your home is sparkling clean and
                welcoming.
              </p>
            </Col>
            <Col xs={12} md={4} className='home-services__service px-4'>
              <img
                src='https://res.cloudinary.com/twomaidsengi/image/upload/c_scale,f_auto,q_auto,w_300/v1670257150/hubsite-reskin/smile-badge.webp'
                width='100%'
                height='100%'
                alt='A badge with a smile'
                loading='lazy'
              />
              <h3>PROVEN HISTORY IN THE HOUSE CLEANING SERVICE</h3>
              <p>
                We are a multi-award-winning cleaning company that is over 15
                years in the making. Trusted by over 500 households daily
                nationwide, our track record speaks for itself. We take pride in
                delivering consistent, reliable cleaning services tailored to
                meet your needs.
              </p>
            </Col>
          </Row>
          <Link href='/services/' passHref prefetch={false}>
            <a className='btn btn-primary services-btn'>VIEW OUR SERVICES</a>
          </Link>
        </Section>

        <HomeDifferenceSection />

        <Section lg={11} className='reviews-carousel-container'>
          <Row className='justify-content-center'>
            <Col xs={12} md={8} className='text-center'>
              <h2>A Few Kind Words</h2>
            </Col>
          </Row>
          <LazyLoad once>
            <ReviewsCarousel />
          </LazyLoad>
          <div className='text-center'>
            <Link href='/reviews/' passHref prefetch={false}>
              <a className='btn btn-primary dark-bg-btn'>Read More Reviews</a>
            </Link>
          </div>
        </Section>
        <FeaturedInSection />
      </Layout>
    </FranchiseeProvider>
  );
};

export default IndexPage;
